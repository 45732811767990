<template>
  <div>
    <h2>Nutzlast</h2>
    <p>Die Nutzlast beträgt für Standardfälle: Wohnen 1,5 kN/m², Flure+Büroflächen 2,0 kN/m². Leichte Trenn­wände sind zusätzlich mit 1,2 kN/m² zu berücksichtigen.</p>

    <h2>Ausbaulasten</h2>
    <p>Die Ausbaulasten sind die ständigen Lasten aus Dämmung, Estrich, Abklebung, Bodenaufbau, Installationen, etc. Das Eigengewicht der Platte inkl. Fugenverguß wird intern automatisch berücksichtigt.</p>

    <h2>Aussparungen / Einzellasten</h2>
    <p>Deckenaussparungen können abhängig von der Aus­nutzung bis zu 1/3 der Plattenbreite ausgeführt werden. Untersucht werden können Aussparungen an den Auflagern und in Plattenmitte bzw. der Stelle mit dem höchstem Biegemoment.
      Zur normalen Streckenlast können Einzellasten (Länge b=0 eingeben!) und Blocklasten definiert werden. Diese Lasten beziehen sich immer auf einen 1,0 m breiten Plattenstreifen.</p>
  </div>
</template>

<script>
export default {
  name: "PayloadHelp"
};
</script>

<style lang="scss">
</style>